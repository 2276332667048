/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {ProjectCard} = _components;
  if (!ProjectCard) _missingMdxReference("ProjectCard", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Projects"), "\n", React.createElement(ProjectCard, {
    title: "Galoy",
    link: "https://galoy.io/",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "Consultant mobile developer for Galoy mainly working on the Bitcoin Beach\nWallet.")), "\n", React.createElement(ProjectCard, {
    title: "Rocket Writer",
    link: "https://github.com/daviroo/rocket-writer",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "A project I created to help content creators to optimise their writing for SEO\npurposes.")), "\n", React.createElement(ProjectCard, {
    title: "Dynamic Flooring Services",
    link: "https://www.dynamic-flooring-services.co.uk/",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "A website for a flooring company to showcase the services they provide and\nenables an online funnel for contacts.")), "\n", React.createElement(ProjectCard, {
    title: "Optocare",
    link: "https://github.com/daviroo/optocare-public-api",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "A service I created to assist elderly people who require telephone call\nreminders for medication etc.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
