/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    blockquote: "blockquote",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Constultant Software Engineer"), "\n"), "\n", React.createElement(_components.p, null, "I have been doing software engineering for over 8 years and have built up a range of experience in a diverse range of technical subjects. I specialise in front end web/mobile development but can also provide value on the backend. I love to work on innovative projects which are looking to have a high impact on the world."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
